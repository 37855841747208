@import "@angular/material/theming";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~ag-grid-community/styles//ag-grid.css";
@import "~ag-grid-community/styles//ag-theme-balham.css";
@import "assets/fonts/font.scss";
@import "~primeng/resources/themes/bootstrap4-light-blue/theme.css";
@import "~primeng/resources/primeng.min.css";
@import "~primeicons/primeicons.css";
@import url(../src/assets/styles/material.icon);

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: roboto, sans-serif;
}
body {
  margin: 0;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: var(
    --mdc-dialog-container-shape,
    var(--mdc-shape-medium, 4px)
  );
  background: transparent !important;
  overflow: visible;
}
.grey-button {
  height: 35px;
  outline: none;
  border: 0;
  padding: 0 14px;
  text-transform: uppercase;
  color: black;
  background: rgb(221, 221, 221) 0% 0% no-repeat padding-box;
  cursor: pointer;
  border-radius: 2px;
  opacity: 100%;
}
.black-button {
  height: 35px;
  outline: none;
  border: 0;
  padding: 0 14px;
  text-transform: uppercase;
  color: white;
  background: linear-gradient(#000, rgb(49, 49, 49));
  cursor: pointer;
  border-radius: 2px;
  transition: transform 0.2s;
  box-shadow: 0.2s;
}

.black-button:active{
  transform: scale(0.95) !important;
  box-shadow: 0 5px 10px rgba(0,0,0,0.2) !important;
}

.popup-cancel-btn {
    width: 87px;
    height: 38px;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #E9F0F3 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #c6d8e0;
    opacity: 1;
}

.danger-bk {
  background-color: #f01611;
}

.warning-bk {
  background-color: #faa732;
}

.yellow-bk {
  background-color: #2688da;
}

.success-bk {
  background-color: #5bb75b;
}

.black-button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.grid-agg {
  width: auto;
  padding: 10px 10px !important;
}
.ag-theme-balham .ag-header-cell,
.ag-theme-balham .ag-header-group-cell {
  padding-left: 12px;
  padding-right: 12px;
}

textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 1px 6px #00000029;
  border: 1px solid #d7dce1;
  border-radius: 2px;
  font-size: 13px;
  padding: 0.5em;
  color: #676a6e;
}

.roboto {
  font-family: roboto, sans-serif;
}

.roboto-medium {
  font-family: roboto-medium, sans-serif;
}

.roboto-bold {
  font-family: Roboto-Bold, sans-serif;
}

.ag-theme-balham {
  --ag-header-height: 40px;
  --ag-header-foreground-color: #676a6e;
  --ag-header-background-color: #e4ecf0 !important;
}

::ng-deep .ag-theme-balham {
  font-family: Roboto, sans-serif !important;
}

a {
  text-decoration: none;
}

.btn-link {
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
}
.vpacs-btn-table {
  background: transparent linear-gradient(180deg, #ffffff 0%, #e9f0f3 100%) 0%
    0% no-repeat padding-box;
  outline: none;
  border: 1px solid #c6d8e0;
  border-radius: 2px;
  font-size: 13px;
  font-weight: 500;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.vpacs-btn-table-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.btn-table {
  background: transparent linear-gradient(180deg, #ffffff 0%, #e9f0f3 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  font: 400 11px/18px Roboto-Medium, sans-serif;
  border: 1px solid #d7dce1;
  border-radius: 2px;
  padding: 0.5em;
  color: #676a6e;
  height: 35px;
  width: 118px;
}

.btn-table:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

li {
  list-style-type: none;
}

.mat-dialog-container {
  outline: 0;
  width: 100%;
  height: 100%;
  display: block;
  padding: 0px !important;
  border-radius: 4px;
  box-sizing: border-box;

  min-height: inherit;
  max-height: inherit;
}

.disable_icon {
  color: rgb(196, 196, 196) !important;
  pointer-events: none;
  cursor: not-allowed !important;

}

.ag-theme-balham .ag-header {
  font-weight: 500 !important;
  text-transform: uppercase;
}
.ag-theme-balham .ag-header-group-cell {
  font-weight: 500 !important;
  font-size: 11px;
}

.ag-theme-balham .ag-header-cell,
.ag-header-cell-label {
  font-size: 11px;
  color: #676a6e;
  font-weight: 600 !important;
  display: flex;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue";
}

.ag-theme-balham .ag-header-row:not(:first-child) .ag-header-cell,
.ag-theme-balham
  .ag-header-row:not(:first-child)
  .ag-header-group-cell.ag-header-group-cell-with-group {
  background-color: #f4f0df;
}

.ag-theme-balham input[class^="ag-"]:not([type]),
.ag-theme-balham input[class^="ag-"][type="text"],
.ag-theme-balham input[class^="ag-"][type="number"],
.ag-theme-balham input[class^="ag-"][type="tel"],
.ag-theme-balham input[class^="ag-"][type="date"],
.ag-theme-balham input[class^="ag-"][type="datetime-local"],
.ag-theme-balham textarea[class^="ag-"] {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 1px 6px #00000029 !important;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  height: 30px;
  min-width: 100%;
}
.ag-theme-balham .ag-icon-menu {
  display: none;
}
.ag-row {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 11px;
}

.ag-theme-balham .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-balham .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-balham .ag-layout-print .ag-center-cols-clipper,
.ag-theme-balham .ag-layout-print .ag-center-cols-container {
  min-height: 180px !important;
}

.ag-row .ag-cell {
  font-size: 13px;
  display: flex;
  align-items: center;
  height: 24px;
  text-overflow: clip;
  white-space: normal !important;
  color: #161718;
  opacity: 1;
  margin-left: 10px;
}
.row-odd {
  background-color: #f7f9fb !important;
}
.row-even {
  background-color: white !important;
}
.row-expanded {
  background-color: #f5f7ee !important;
}
.row-color {
  background-color: #b1f2ff !important;
}

input[type="text"],
input[type="date"],
input[type="number"],
select,
.mat-sel,
.timepicker {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 1px 6px #00000029;
  border: 1px solid #d7dce1;
  border-radius: 2px;
  font-size: 13px;
  padding: 0.5em;
  color: #676a6e;
  max-width: 180px;
  height: 40px;
  height: 35px;
}
input[type="text"]:focus,
input[type="date"]:focus,
input[type="number"]:focus,
select:focus {
  border: 1px solid #85a2af;
  outline: none;
  box-shadow: inset 0px 1px 6px #85a2af;
}
::-webkit-datetime-edit {
  text-transform: uppercase;
}
::-webkit-calendar-picker-indicator {
  width: 14px;
  height: 16px;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

input[type="text"]:disabled {
  background-color: #9c9c9c25;
}

input[type="email"]:disabled {
  background-color: #9c9c9c25;
}

.breadcrumb-item + .breadcrumb-item {
  &:before {
    content: unset !important;
  }
}

input[type="checkbox"] {
  width: 30px !important;
}

.ag-checkbox-input-wrapper input {
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  margin-right: 2px;
  opacity: 0.5;
  box-shadow: inset 0 1px 6px #00000029;
  filter: invert(0.6);
  border: 1px solid #eef1f3;
  border-radius: 2px;
}

.form-control {
  border-radius: 2px !important;
  color: #676a6e;
}
.rotate {
  width: 30px;
  height: 30px;
  transform: rotate(90deg);
  margin-top: 20px;
}

.mat-dialog-content {
  display: block;
  margin: 0 0px !important;
  padding: 10px 10px !important;
  max-height: 65vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
::ng-deep.title {
  font-size: 24px !important;
  color: #161718;
}

.mat-typography h2 {
  font: 500 18px/32px Roboto-Medium, sans-serif;
  margin: 0;
}
.mat-typography h4 {
  font: 400 15px/24px Roboto-Medium, sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.mat-typography {
  font: 400 13px/20px Roboto, Helvetica Neue, sans-serif;
}

#btn {
  background: linear-gradient(#000, rgb(49, 49, 49));
  border: none;
  color: white;
  height: 35px;
  font-size: 11px;
  border-radius: 2px;
}
#btn:hover {
  background-color: rgb(39, 32, 32);
  transition: 0.3s ease-in;
}
.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border: none !important;
  outline: none !important;
}
.form-select {
  font-size: 13px !important;
  border-radius: 2px !important;
  color: #676a6e !important;
}
.ag-cell-value {
  font-family: roboto, sans-serif !important;
  font-size: 11px;
}

.vr {
  opacity: 0.09 !important;
  height: 25px !important;
  align-self: center !important;
}

.myDiv {
  background: #e4ecf0 0% 0% no-repeat padding-box;
  text-align: left;
  color: #161718;
  box-shadow: inset 0px -1px 0px #bcd1e0;
}

.grid {
  display: grid;
  padding: 0px 20px;
  font-size: 13px;
  row-gap: 3px;
  label {
    font: normal normal bold 13px/19px Roboto, sans-serif;
    color: #676a6e;
  }
  span {
    font-family: Roboto-Medium, sans-serif;
  }
}

.pagination {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 8px #00000029;
}

.checkcontainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 13px;
}

.checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px !important;
  margin-left: 1rem;
  z-index: 999;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 1px 3px #00000029;
  border: 1px solid #d7dce1;
  opacity: 1;
  border-radius: 2px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkcontainer .checkmark:after {
  left: 7px;
  top: 1px;
  width: 8px;
  height: 15px;
  border: solid #2386da;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkcontainer input:checked ~ .checkmark:after {
  display: block;
}

.wrapper {
  height: 100vh;
  display: block;
}

.ngx-timepicker {
  border-bottom: none !important;
}

::ng-deep.ngx-timepicker-control__input {
  font-size: 13px !important;
  color: #676a6e !important;
}
.firstPage,
.previousPage,
.nextPage,
.lastPage,
.paginate_drpdwn {
  cursor: pointer;
}
.edit-event-mat-dialog .mat-dialog-container {
  overflow: hidden;
}
.edit-event-mat-dialog .main {
  overflow-y: scroll;
  height: 487px;
}

.redFont {
  color: red;
}

.bold {
  font-weight: bold;
}
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important;
  background-color: #00000029 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #2688da !important;
}

.mat-radio-container .mat-radio-outer-circle {
  border-color: #00000029 !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2688da !important;
}
.required-color {
  border-left: 0.3rem solid #d20000;
}
.create_footer {
  height: 50px;
  background: #e4ecf0 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #00000014;
  border: 1px solid #bcd1e0;
  bottom: 30px;
  width: 100%;
}
.cancel {
  vertical-align: -webkit-baseline-middle;
  cursor: pointer;
  font: normal normal bold 13px/18px;
  color: #2688da;
  background: transparent;
  border: none !important;
  height: 35px;
  font-weight: 500;
}
.bold-label {
  font-weight: bold;
}
.header-display {
  font: bold 13px/16px Roboto, Helvetica Neue, sans-serif;
  color: #161718;
}
.required-display {
  font: normal 13px/16px Roboto, Helvetica Neue, sans-serif;
}
.cancelalign {
  float: left;
}
.blackbtnalign,
.whitebtnalign {
  float: right;
}

.status-holder {
  display: flex;
  border-radius: 25px;
  justify-content: center;
  text-transform: uppercase;
  width: 153px;
}

.status-holder.Completed{
  background: #92d050;
  color: #000000;
}
.status-holder.Due, .status-holder.Deleted,.status-holder.Rejected{
  background: #ff4747;
  color: #000000;
}

.status-holder.Cancelled{
  color: #000000;
  background: #f97523;
}

.status-holder.Firmed{
  background: #548235;
  color: #000000;
}

.status-holder.Active{
  background: #0192d8;
  color: #000000;
}


.status-holder.Awaiting_Labels{
  background: #cfe4c2;
  color: #000000;
}

.status-holder.RFC{
  background: #80ffff;
  color: #000000;
}

.status-holder.AWAIT_PO{
  background: #42fce6;
  color: #000000;
}

.status-holder.ECI_DATA{
  background: #D9544D;
  color: #000000;
}

.status-holder.Shipped{
  background: #51ac8c;
  color: #000000;
}

.status-holder.Pending{
  background: #ffff00;
   color: #000000;
}

.status-holder.Manifest_Generated{
  background: #a9d08e;
  color: #000000;
}


.status-holder.Awaiting_Approval{
  background: #ffc000;
  color: #000000;
}

.status-holder.Revised{
  background: #ffb56a;
  color: #000000;
}

.status-holder.NAMC_Revised{
  background: #ff8000;
  color: #000000;
}

.status-holder.New{
	background: #fc04c1;
	color: #000000;
}
.status-holder.Processed{
	background: #2704fc;
	color: #FFFFFF;
}

.status-holder.Unknown{
	background: #eae6b5;
	color: #000000;
}

.sapStatusval{
    font-size: 13px;
    font-weight: normal;
    font-variant: normal;
    font-family: roboto, sans-serif;
}

.sap-status.error::before{
	content: "\f071";
	color: red;
}

.sap-status.sent::before{
	content: "\f058";
	color: green;
}

.sap-status.na::before{
	content: "\f05a";
	color: blue;
}

.sap-status.warning::before{
	content: "\f12a";
	color: yellow;
}


.btnposition{
  position: fixed;
}

.cancel-button {
  border: none;
  margin-right: auto;
  color: #2688da;
  background: none;
  padding-bottom: 31px;
  padding-left: 20px;
  transition: transform 0.2s;
  box-shadow: 0.2s;
}

.cancel-button:active{
  transform: scale(0.95) !important;
  text-decoration: underline !important;
}

.cancel-button:hover{
  text-decoration: underline !important;
}

.b2-button {
  margin-right: 10px;
  border: 1px solid #d7dce1;
  background: transparent linear-gradient(180deg, #ffffff 0%, #e9f0f3 100%) 0% 0% no-repeat padding-box;
  padding: 10px 18.93px 10px 18.93px;
  font-weight: 500;
  color: #676a6e;
  font-size: 13px;
  line-height: 16px;
  font-family: Roboto, sans-serif;
  height: 38px;
  width: auto;
  border-radius: 4px;
  opacity: 0.90;
  transition: transform 0.2s;
  box-shadow: 0.2s;
}

.b2-button:active{
  transform: scale(0.95) !important;
  box-shadow: 0 5px 10px rgba(0,0,0,0.2) !important;
}

.b2-button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.add {
  background: #161718 !important;
  color: white !important;
}
