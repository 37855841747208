@font-face {
    font-family: Roboto-Bold;
    src: url(./Roboto-Bold.ttf);
}

@font-face {
    font-family: roboto;
    src: url(./Roboto-Regular.ttf);
}

@font-face {
    font-family: roboto-medium;
    src: url(./Roboto-Medium.ttf);
}